import React, { useEffect, useState} from "react";
import ColorPicker from "./colorpicker";
import "./Settings.css";
// import { useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AutoThis = ({arg})=>{
  return (
    <> 
      <p>{arg} is selected  </p>
      <p> Default Add to cart button show on your product page.</p>
    </>
  )
}
const ManualThis = ({arg})=>{
  return (
    <> 
      <p>{arg} is selected  </p>
      <p>
        Add the code in your activated theme product-template.liquid file after the end of form tag
           &lt;div class="Cartcloner"&gt; &lt;/div&gt;
              </p>
    </>
  )
}
const AddThis = ({arg})=>{
  return (
    <> 
      <p>{arg} is selected  </p>
      <p>Add to cart redirect your customer on cart page.</p>
    </>
  )
}
const BuyThis = ({arg})=>{
  return (
    <> 
      <p>{arg} is selected  </p>
      <p>Buy it now redirect your customer on checkout page .</p>
    </>
  )
}
const SettingsComponent = ({ arg: { setting, setSetting, formHandler } }) => {
  
  const[previewEnabler,setPreviewEnabler] = useState(false);

  console.log("setting->>>>", setting);

  // let [searchParams] = useSearchParams();
  const code = sessionStorage.getItem("code");

  var decoded = jwt_decode(code);
  // console.log(decoded.jwttoken);

  const shopname = decoded.jwttoken;

  const url = "https://cart-cloner.herokuapp.com/getshop";
  // console.log(url);


  const auto =(e) => {
    toast.info(<AutoThis arg="Auto" />);
  }
  const manual =(e) => {
    toast.info(<ManualThis arg="Manual" />);
  }
  const add =(e) => {
    toast.info(<AddThis arg="Add" />);
  }
  const buy =(e) => {
    toast.info(<BuyThis arg="Buy" />);
  }
 

  useEffect(() => {
    axios
      .post(url, {
        shopify_domain: shopname,
      })
      .then(({ data }) => {
        const {
          name,
          buttoncolor,
          fontcolor,
          fontsize,
          quantity,
          button,
          shake,
          location,
          button_type,
        } = data;
        setSetting({
          ...setting,
          name,
          buttoncolor,
          fontcolor,
          fontsize,
          quantity,
          button,
          shake,
          location,
          button_type,
        });

        console.log("from here =>", data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const inputHandler = (e) => {
  //   setSetting({...setting, [e.target.name]: setting[e.target.name] === "checked" ?  })
  // };
  return (
    
    <form method="post" onSubmit={(e) => formHandler(e)}>
      <div className="position-absolute png_imgs">
        <div className="left">
          <div className="png1L">
            <img src="asset/1.png" alt=".." style={{}} />
          </div>
          <div className="png2L">
            <img src="asset/3.png" alt=".." style={{}} />
          </div>
          <div className="png3L">
            <img src="asset/4.png" alt=".." style={{}} />
          </div>
        </div>
        <div className="right">
          <div className="png1R">
            <img src="asset/2.png" alt=".." style={{}} />
          </div>
          <div className="png2R">
            <img src="asset/5.png" alt=".." style={{}} />
          </div>
          <div className="png3R">
            <img src="asset/6.png" alt=".." style={{}} />
          </div>
        </div>
      </div>
      <div className="position-absolute images">
        <img src="asset/left.png" alt=".." style={{ marginLeft: "3%" }} />
        <img src="asset/right.png" alt=".." style={{}} />
      </div>

      <div className="card">
        <span style={{width:'fit-content',position:'absolute',right:'20px',top:'0',
        fontSize:'25px',cursor:'pointer'}} onClick={()=> setPreviewEnabler(!previewEnabler)}>&#128065;</span>
        <div className="card-body">
          <div className="mb-3">
            <label for="exampleInput" className="form-label">
              Button Name
            </label>
            <input
              name="name"
              value={setting.name}
              onChange={(e) =>
                setSetting({ ...setting, [e.target.name]: e.target.value })
              }
              type="text"
              className="form-control"
              id="InputText"
              placeholder="Add to cart"
              required
            />
          </div>

          <br />
          <div className="row">
            <div className="mb-3 col btn_color">
              <label for="exampleInput" className="form-label">
                Button Color
              </label>
              <br />
              <ColorPicker
                arg={{ color: setting, setColor: setSetting, id: "color1" }}
              />
            </div>
            <div className="mb-3 col btn_color">
              <label for="exampleInput" className="form-label">
                Font Color
              </label>
              <br />
              <ColorPicker
                arg={{ color: setting, setColor: setSetting, id: "color2" }}
              />
            </div>
          </div>
          <div className="mb-3">
            <label for="exampleInput" className="form-label">
              Font Size
            </label>
            <input
              name="fontsize"
              value={setting.fontsize}
              onChange={(e) =>
                setSetting({ ...setting, [e.target.name]: e.target.value })
              }
              type="text"
              className="form-control"
              id="InputText"
              placeholder="16"
              required
            />
          </div>

          <br />

          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              name="quantity"
              value={setting.quantity}
              checked={setting.quantity === "checked" ? true : false}
              onChange={(e) =>
                setSetting({
                  ...setting,
                  quantity:
                    setting.quantity === "checked" ? "unchecked" : "checked",
                })
              }
            />
            <label className="form-check-label" for="flexSwitchCheckChecked">
              Enable Quantity Box
            </label>
          </div>
          <br />
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={setting.button === "checked" ? true : false}
              id="flexSwitchCheckChecked2"
              value={setting.button}
              name="button"
              onChange={(e) =>
                setSetting({
                  ...setting,
                  button:
                    setting.button === "checked" ? "unchecked" : "checked",
                })
              }
            />
            <label className="form-check-label" for="flexSwitchCheckChecked">
              Enable add to cart button
            </label>
          </div>
          <br />
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={setting.shake === "checked" ? true : false}
              id="flexSwitchCheckChecked3"
              value={setting.shake}
              name="shake"
              onChange={(e) =>
                setSetting({
                  ...setting,
                  shake: setting.shake === "checked" ? "unchecked" : "checked",
                })
              }
            />
            <label className="form-check-label" for="flexSwitchCheckChecked">
              Enable animated effect
            </label>
          </div>
          <br />
          <br />
          <label className="form-label">SELECT BUTTON EMBEDDED OPTION</label>
          <br />
          <div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="location"
              id="inlineRadio1"
              value="Auto"
              checked={setting.location !== "Manual" ? true : false}
              onChange={(e) =>
                {setSetting({ ...setting, [e.target.name]: e.target.value })
                ;auto(e)}}
            />
            <label className="form-check-label" for="inlineRadio1">
              Auto(Default)
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input "
              type="radio"
              name="location"
              id="inlineRadio2"
              value="Manual"
              checked={setting.location === "Manual" ? true : false}
              onChange={(e) =>
                {setSetting({ ...setting, [e.target.name]: e.target.value })
                ;manual(e)}}
            />
            <label className="form-check-label" for="inlineRadio2">
              Manual
            </label>
            
          </div>
          <br />
          <br />
          <br />
          <label className="form-label">SELECT BUTTON TYPE</label>
          <br />
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="button_type"
              id="inlineRadio1"
              value="Buy"
              checked={setting.button_type === "Buy" ? true : false}
              onChange={(e) =>
                {setSetting({ ...setting, [e.target.name]: e.target.value })
                ;buy(e)}}
            />
            <label className="form-check-label" for="inlineRadio1">
              Buy it now
            </label>
            
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="button_type"
              id="inlineRadio2"
              value="Add"
              checked={setting.button_type !== "Buy" ? true : false}
              onChange={(e) =>
                {setSetting({ ...setting, [e.target.name]: e.target.value })
                ;add(e)}}
            />
            <label className="form-check-label" for="inlineRadio2">
              Add to cart
            </label>
            
          </div>
          <br />
          <br />
          <button
            // onClick={response}
            type="submit"
            className="btn saveBtn"
            style={{ width: "100%" }}>
            Save
          </button>
          <ToastContainer 
            position="top-right"
            autoClose={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            />
        </div>
      </div>
      </div>
      {
          previewEnabler &&
          <div style={{position:'absolute',top:'0',left:'50%',transform:'translate(-50%)',height:'100vh',
          width:'35rem',backgroundColor:'#fff',boxShadow:'0px 0px 39px 7px rgb(0 0 0 / 75%)',zIndex:'99'}}>
            <span style={{fontSize:'25px',width:'fit-content',margin:'10px',float:'right',cursor:'pointer'}}
              onClick={()=> setPreviewEnabler(!previewEnabler)}  >&#x2715;</span>
              <div>
                <button style={{backgroundColor:setting.buttoncolor,fontSize:`${setting.fontsize}px`,color:setting.fontcolor}} class="install__btn">{setting.name}</button>
                {
                setting.quantity ==='checked' &&
                <input
                id="showQuantityBox"
                type="number"
                value={setting.number}
                placeholder="1"
                 min="1" className="install__input"/>
              }
              </div>
          </div>
        } 
    </form>
  );
};
export default SettingsComponent; 