import React from "react";
import styled from "styled-components";

const Container = styled.span`
  display: inline-flex;
  align-items: center;
  width: 150px;
  max-width: 150px;
  padding: 4px 12px;
  background: #fff;
  border-radius: 4px;

  input[type="color"] {
    margin-right: 8px;
    -webkit-appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    &::-webkit-color-swatch-wrapper {
      padding: 0;
      width: 14px;
      height: 14px;
    }
    &::-webkit-color-swatch {
      border: 1px solid #bfc9d9;
      border-radius: 4px;
      padding: 0;
    }
  }

  input[type="text"] {
    border: 1px solid;
    width: 100%;
    font-size: 14px;
  }
`;

const ColorPicker = ({ arg: { color, setColor, id } }) => {
  const colorHandler = (e)=>{
    setColor({...color, [e.target.name]:e.target.value});
  }
  return (
    <Container>
      <input
        type="color"
        id={id}
        name={`${id === "color1" ? "buttoncolor" : "fontcolor"}`}
        color={id === "color1" ? color.buttoncolor : color.fontcolor}
        value={id === "color1" ? color.buttoncolor : color.fontcolor}
        onChange={(e) =>
          colorHandler(e)
        }
      />
      <span htmlFor={id} value={id === "color1" ? color.buttoncolor : color.fontcolor}>
        {id === "color1" ? color.buttoncolor : color.fontcolor}
        
      </span>
    </Container>
  );
};
export default ColorPicker;
