import React from "react";
import { useNavigate } from 'react-router-dom';
import './home.css';
// import {Link} from 'react-router-dom';
// import axios from "axios";

const Home = ({inputValue, setInputValue}) => {
  const navigate= useNavigate();
//   useEffect(()=>{
//     (async()=>{
//         const { data } =  await axios({
//            url:"/hello",
//            method:"get"
//          });
//          console.log("this data => ", data);
//      })();
// }, [])
  //const [shop, setShop] = useState({ shopName:shop });
  

 
  const changeValue = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue.replace('https://', '').replace('/',''));
}

const handleInstall = (e)=>{
  e.preventDefault();
  console.log(inputValue);
}
console.log(inputValue);
  
  return (
    <form onSubmit= {handleInstall} className="needs-validation login" novalidate>
      <div className="container p-5">
        <div className="form-group mb-4">
          <label forHtml="exampleInputEmail1">Enter your URL Shop</label>
          <input
            type="url"
            name="shop"
            required
            autoComplete="off"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={changeValue}
            placeholder="ShopName.myshopify.com"
          />
        </div>
        {/* <button
          className="btn btn-large btn-block btn-primary"
          type="submit"
          name="submit"
          value="Install"
        >
          Submit
        </button> */}

      {/* <Link to='/shopify'> <button className="btn btn-large btn-block btn-primary"
          type="submit"
          name="submit"
          value="Install" onSubmit={changeValue}>Submit</button> </Link> */}
                    
        <button className="btn btn-large btn-block btnSubmit" type="button" name="submit" onClick={()=>{ inputValue && navigate('/shopify')}} value="Install">Submit</button>
      </div>
    </form>
  );
};

export default Home;