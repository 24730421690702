import React from 'react';
import DashBoardTheme from "../../component/dashboardTheme";
import './Document.css'
import { Link } from 'react-router-dom';
const Index = () => {


  return (
  <DashBoardTheme>
        <div class="container">
                <div class="panel panel-default">
                <div class="panel-body">
                <h2>Installation instructions</h2>
                <hr />
                <p>You can use Cart Cloner in TWO way:</p>
                <pre>1. Auto (Default)</pre>
                <pre>2. Manual</pre>
                <b>Auto (Default)</b>
                <p>When you install this app, automatically Cart Cloner button will be added.
                Default position will be below the product description on product page.
                After installation, you can configure settings to your added button through App Settings i.e.:.</p>
                <p>A.<u> Button name</u> : It accepts all the text cases for example , Uppercase, Lowercase etc.</p>
                <p>B. <u>Button color</u> : For adding button background color, color should be filled in 
                color code for example FFFFFF, #FFFFFF.It does not accept color name like blue, pink, red etc.</p>
                <p>C. <u>Font color</u> : For adding font color, color should be filled in color code for example  FFF7B6, #FFF7B6.
                It does not accept color name like green, black, white etc.</p>
                <p>D.  <u>Font size:</u>(should be in px) like 16 ,20 etc.</p>
                <p>E. <u> Enable quantity box </u>: To show quantity box with Cart Cloner  button, you can enable 
                the quantity box just by turning on the button to right and disable the quantity box just by turning off the button to left. </p>
                <p>F. <u> Enable Cart Cloner button </u>: To show/hide Cart Cloner button on product page, 
                you can enable/disable the button by using toggle button.</p>
                <p> After have makeing  all the changes,Save it.</p>
                <p> Visit your product page to see the changes made by you through settings.</p>
                </div>
                </div>
                <div class="panel panel-default">
                <div class="panel-body">
                        <b>Manual</b>
                        <p>Additionally, we can manually paste the code at specific position at our product page Shopify liquid code.</p>
                        <p>Go to App Settings, you need to select Manually option and save it. 
                This code snippet display Cart Cloner button on your product page</p>
                        <hr />
                        <p>Copy the following code snippet to your clipboard: </p>
                        <p class="ui-code">&lt;div class="Cartcloner"&gt; &lt;/div&gt;</p>
                <p />Paste the snippet in your sections/product-template.liquid or product.liquid file where you want your add to cart button to appear. 
                Usually this is just below the {`{{ product.description }}`}tag, the code may look something like this:<p />
                <p class="ui-code">&lt;div class="product-single__description rte" itemprop="description"&gt;
                {`{{ product.description }}`}
                &lt;/div&gt;</p>
                <p class="ui-code">&lt;div class="Cartcloner"&gt; &lt;/div&gt;</p>
                <p class="ui-code">
        {`{% if section.show_share_buttons% }`}
                {`{% include 'social-sharing', share_title: product.title, share_permalink: product.url, share_image: product % }`}
                {`{% endif %}`}
                </p>

                <p>Note: For few themes {`{{ product.description }}`}code may be at different position.</p>
                <p>Once added, save your theme.</p>
                <p> You can add number of Cart Cloner buttons with / without quantity box on your product pages and can make changes to the added button through settings as described above. 
                Visit your product page to see the changes made by you through settings.</p>
                <p> To make more changes through CSS on Cart Cloner button, you can go to ‘Custom CSS’ tab where you can add your own Custom CSS.</p>

                </div>
                <Link to='/settings'> 
                <button className="btn btn-large btn-block "
                        type="button"
                        name="Setting"
                        value="Setting">Setting</button> 
                </Link>
                </div>
                
        </div>
  </DashBoardTheme>
  
  )
}
export default Index;