import React from "react";
import BrowserRouter from "./routes";

const App = () => {
    // const resultRef=useRef(null);
    // const getServerData=()=>{
    //   fetch("/getDataFromServer", {
    //     method: 'POST', //or PUT
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({id:123})
    //   })
    //   .then(response =>{
    //     return response.json;
    //   })
    //   .then(result =>{
    //     if(resultRef.current!=null){
    //        resultRef.current.innerHTML=result.content;
    //     }
    //   })
    //   .catch(error =>{
    //     console.error(error);
    //   });
    // }
  
  return (
    <React.Fragment>
      <BrowserRouter />
    </React.Fragment>
  );
}

export default App;
