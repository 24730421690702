import React, {useState} from 'react';
import DashBoardTheme from "../../component/dashboardTheme";
import SettingsComponent from './SettingsComponent';
import './Settings.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
// import { useSearchParams } from 'react-router-dom';


const Index = () => {
      // let [searchParams] = useSearchParams();
      const code = sessionStorage.getItem("code");
  
      var decoded = jwt_decode(code);
      // console.log(decoded.jwttoken);
  
      const shopname = decoded.jwttoken;

  
    const url ="/addUpdate";
   
// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
    const [setting, setSetting] = useState({
      shopify_domain:shopname, name:'', buttoncolor:'', fontcolor:'', fontsize:'', quantity:'', button:'', shake:'', location:'', button_type:''
    });

    const formHandler = (e)=>{
      e.preventDefault();
      axios.post(url, {
        shopify_domain:shopname,
        name: setting.name,
        buttoncolor: setting.buttoncolor,
        fontcolor: setting.fontcolor,
        fontsize: setting.fontsize,
        quantity: setting.quantity,
        button: setting.button,
        shake: setting.shake,
        location: setting.location,
        button_type: setting.button_type
      })
      .then(res=>{
        toast.success("Successfully Updated");
        console.log("res----", res.data);
      }) 
      .catch(err=>{
        toast.error("Updates failed");
      })  
      
      console.log("setting", setting);

      }


      
    //   Axios.post("http://localhost:3000/bardata", {
    //     setting:setting
    //   }).then(() => {
    //     alert("Succesfull");
    //   });
    // };

  return (
      <DashBoardTheme>
          <SettingsComponent  arg={{ setting, setSetting, formHandler }} />
      </DashBoardTheme>
  
  )
}
export default Index;