import React from "react";

const Header = ()=>{
    return (
        <React.Fragment>
            {/* <div className='row m-0' style={{background:'none', color:"#000", padding:"10px"}}>
                <div className="d-flex justify-content-end" >
                    <div>BY ENS ENTERPRISES</div>
                </div>
    </div>*/}
        </React.Fragment>
    )
}

export default Header;