import React from "react";
import OffCanvas from "./offCanvas";
import './sidebar.css'

const Index = () => {
  return (
    <div className='sidebar'>    
        <div className='text-center'>
          <span className="offcanvasBtn">
          <OffCanvas />
          </span>
        </div>
      <div className='slanted'>
      <h1>CART CLONER</h1>
      </div>
      <div className="sidebar__end">
          BY ENS ENTERPRISES
          </div>
    </div>
  );
};

export default Index;

