import React, { useState, useEffect } from "react";
import DashBoardTheme from "../../component/dashboardTheme";
import "./Custom.css";
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useSearchParams } from 'react-router-dom';

const Index = () => {
      // let [searchParams] = useSearchParams();
      const code = sessionStorage.getItem("code");
  
      var decoded = jwt_decode(code);
      // console.log(decoded.jwttoken);
  

  const shopname = decoded.jwttoken;

  const [css, setCss] = useState({shopify_domain:shopname, customs:''});
  const[responsecss, setResponsecss] = useState('')
  
  const urlCss= '/customcss';

  const url = "https://cart-cloner.herokuapp.com/findcss";
  // console.log(urlCss);

  const handleSubmit = (e)=>{
    e.preventDefault();
    axios.post(urlCss, {
      shopify_domain:shopname,
      customs: css.customs,
    })
    .then(res=>{
      toast.success("Check your product page!");
      console.log(res.updatecustom);
      setResponsecss(res.customs)
      console.log(responsecss);

    })  
    .catch(err=>{
      toast.error("Something gone wrong!");
    })   
    console.log(css);
    
  }

  useEffect(() => {
    axios
      .post(url, {
        shopify_domain: shopname,
      })
      .then(({ data }) => {
        const {
          customs,
        } = data;
        setCss({
          ...css,
          customs,
        });

        // console.log("from here =>", data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashBoardTheme>
      <div className="jumbotron" >
        <form method="post" onSubmit={(e)=> handleSubmit(e)}>
         <input type="hidden" name="authenticity_token" value={decoded} />
          <input
            type="hidden"
            name="code"
            value={decoded}
           />
          <div className="form-group">
            <label for="exampleFormControlTextarea">
              <b>Please write your custom CSS here:</b>
            </label>
            <textarea
              className="form-control"
              name="customs"
              value={responsecss?responsecss:css.customs}
              onChange={(e) =>
                setCss({[e.target.name]: e.target.value })
              }
              id="exampleFormControlTextarea"
              rows="8"
            >
            
            </textarea>
            <p>
              <b>* Style tag is not required for any CSS property</b>
            </p>

            <div className="form-item">
              <button type="submit" className="btn query buttonCss">
                Save
              </button>
              <ToastContainer/>
            </div>
          </div>
        </form>

        <div className="panel panel-default">
          <div className="panel-body">
            <b>Add Custom CSS:</b>

            <p>
              Add Custom CSS on your add to cart button 
            </p>
           
            <b>For example</b>
            <p className="ui-code">
              {`
								border: 1px solid #919191 !important;`}
            </p>
          </div>
        </div>
      </div>
    </DashBoardTheme>
  );
};
export default Index;
