import {useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import Installer from "../pages/Installer";
import Settings from "../pages/Settings";
import Document from "../pages/Document";
import CustomCss from "../pages/CustomCss";
import NotFoundPage from "../pages/404";
import ProtectedRoute from "../pages/protected";

const ClonerRouter = () => {
    const [inputValue, setInputValue] = useState("");
   
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={ <Home inputValue={inputValue} setInputValue={setInputValue} />} /> 
                    <Route exact path="/shopify" element={ <Installer inputValue={inputValue} />} /> 
                    {/* <Route exact path="/dashboard" element={ <Dashboard /> } /> */}
                    <Route exact path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>  } />
                    <Route exact path="/document" element={<ProtectedRoute><Document /> </ProtectedRoute> } />
                     <Route exact path="/customcss" element={ <ProtectedRoute><CustomCss /></ProtectedRoute>  } /> 
                    <Route exact path="*" element={ <NotFoundPage /> } />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default ClonerRouter;