// import React, { useEffect } from "react";


// const Installer = () => {
//   // let shop = {shop}
//   let scopes = 'read_orders,write_orders';
//   let apiKey = 'db24976dcdbed2fcc99add3c7695be02';
//   let redirect_uri = 'https://cart-cloner.netlify.app/shopify/callback'
//   let oAuthUrl = `https://spark-plug5.myshopify.com/admin/oauth/authorize?client_id=${apiKey}&scope=${scopes}&redirect_uri=${redirect_uri}&state=sasassa321dsad  
//      `;

//   useEffect(() => {
//     window.location.href = oAuthUrl;
  
//   },)
//   return (
//     <p> Redirect </p>
//   );
// };

// export default Installer;



import config from "../../utils/config";
import { useSearchParams } from "react-router-dom";
// const { apikey, scopes, redirecturl } = config;
// REACT_APP_SHOPIFY_API_KEY=63329a2a9a554068b6b2de1045670dc7
// REACT_APP_SHOPIFY_API_SECRET=816e53d7cf8ff1ba0cc5f03de9f00dba
// REACT_APP_SHOPIFY_API_SCOPES=write_script_tags
// REACT_APP_REDIRECT_URI=https://cartcloner.ens.enterprises/auth/callback

const apikey='63329a2a9a554068b6b2de1045670dc7'
const scopes='write_script_tags'
const redirecturl='https://cartcloner.ens.enterprises/auth/callback'




const UrlPage = ({inputValue}) => {
    const [searchParams] = useSearchParams();
    const shop = searchParams.get("shop");
    const hmac = searchParams.get("hmac") || "984294fhsjkdfkjdsfk";
    const oAuthUrl = `https://${shop ? shop : inputValue}/admin/oauth/authorize?client_id=${apikey}&scope=${scopes}&redirect_uri=${redirecturl}&state=${hmac}`;
    window.location.href = oAuthUrl;
    return (
        <img src="asset/giphy1.gif" alt="..." style={{position:'absolute', top:'15vh', left:'65vh'}}/>
    );
}

export default UrlPage;