import Header from '../header';
import SideBar from '../sidebar/index';
import '../../App.css';
import React from "react";

const DashBoardTheme = ({ children }) => {

    return (
        <React.Fragment>
            <div className='container-fluid m-0 p-0'>
            <div className='cartRow'>
                <div className='cartCol1'><SideBar/></div>
                    <div className='cartCol2 '>
                     <Header />
                        {children}
                   
                    </div>
            </div>
                    
            </div>
        </React.Fragment>
    )
}

export default DashBoardTheme;