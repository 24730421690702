import React from "react";
import { Link } from 'react-router-dom';
import './sidebar.css'

const OffCanvas = () => {
  return (
    <>

      <span className="menu_bars" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
        <i className='bi bi-filter-left'></i>
      </span>


    <div className="offcanvas text-dark offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div className="offcanvas-header">
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body text-right">
        <div>
        <h2 className="fw-bold">Cart Cloner</h2>
        </div>
        
          <ul>
          <Link to='/settings' className='nav-link'> <i className="bi bi-gear"></i> Settings</Link>
          <Link to='/document' className='nav-link'> <i className="bi bi-file-earmark-text"></i> Document </Link>
          <Link to='/CustomCss' className='nav-link'> <i className="bi bi-file-code"></i> Custom CSS</Link>
          {/* <Link to='/support' className='nav-link'> <i className="bi bi-chat-right-text"></i> Support</Link> */}
          </ul>


          <div className="sidebar__end">
          BY ENS ENTERPRISES
          </div>
      </div>
    </div>
     
      </>
              
  );
};

export default OffCanvas;
