import { useSearchParams } from "react-router-dom";
import { decodeChecker } from "../../utils/helper";




const InvalidUser = () => <h1> Invalid User.........! </h1>;

const ProtectedRoute = ({ children }) => {
  let [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const localcodeGet = sessionStorage.getItem("code");
  const sessionChecker = decodeChecker(code) || decodeChecker(localcodeGet);

    if (decodeChecker(code)){
        sessionStorage.setItem("code", code);
        
    }

  return sessionChecker ? children : <InvalidUser />;
}


// const InvalidUser = () => (
//     <h1> Invalid User......... ! </h1>
// )

// const ProtectedRoute = ({ children }) => {
//     let [searchParams] = useSearchParams();
//     const code = searchParams.get("code")
//     const localcodeGet = sessionStorage.getItem("code");
//     const redirectHandler = (getcode, children) => {
//         if (decodeChecker(getcode)) {
//             sessionStorage.setItem("code", getcode);
//             return children;
//         } else {
            
//             return <InvalidUser />
//         }
//     }
//     if (localcodeGet) {
//         return redirectHandler(localcodeGet, children);
//     } else {
//         if (code) {
//             return redirectHandler(code, children);
//         } else {
//             console.log("no parm");
//             return <InvalidUser />
//         }
//     }
// }

export default ProtectedRoute;